<template>
  <!--begin::Card-->
  <div>
    <div class="d-flex flex-row">
      <!--begin::Content-->
      <div class="flex-row-fluid">
        <!--begin::Row-->
        <div class="row">
          <div class="col-lg-12">
            <div class="card card-custom gutter-b card-stretch">
              <div class="card-body card-stretch.gutter-b" v-if="contract">
                <!--begin::Details-->

                <div class="row">
                  <div class="col-lg-10">
                    <div class="d-flex flex-wrap flex-sm-nowrap">
                      <v-chip class="mb-4" color="primary">
                        <strong>Main Contract</strong>
                      </v-chip>
                      <a target="_blank" :href="contract?.clickup_task_url">
                        <img
                          class="d-flex align-items-start mx-2"
                          style="height: 2.5rem; width: 2.5rem"
                          src="@/assets/images/clickup.png"
                          alt="clickup"
                        />
                      </a>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div
                      class="d-flex flex-wrap flex-sm-nowrap justify-content-end"
                    >
                      <v-chip
                        class="mb-4"
                        :color="getContractStatuses(contract.status).color"
                      >
                        <strong>{{
                          getContractStatuses(contract.status).text
                        }}</strong>
                      </v-chip>
                    </div>
                  </div>
                  <div class="col-lg-11">
                    <div class="d-flex flex-wrap flex-sm-nowrap">
                      <!--begin: Pic-->
                      <div
                        class="flex-shrink-0 mr-7 mt-lg-0 mt-3 d-flex flex-column"
                      >
                        <div>
                          <div
                            class="image-input image-input-outline profile-avatar"
                            id="kt_profile_avatar"
                          >
                            <div
                              class="image-input-wrapper rounded-circle"
                              :style="{ backgroundImage: `url(${photo})` }"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <!--end::Pic-->

                      <!--begin::Info-->
                      <div class="flex-grow-1">
                        <!--begin::Title-->
                        <div class="d-flex align-items-start">
                          <div class="d-flex mr-sm-10 flex-column">
                            <div class="d-flex align-items-center">
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Talent Name:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold cursor-pointer"
                                    @click="
                                      openLinkInNewTab(
                                        `/dashboard/talent/${contract.talent_id}`
                                      )
                                    "
                                  >
                                    {{
                                      contract?.talent_name
                                        ? contract.talent_name
                                        : "-"
                                    }}
                                  </span></span
                                >
                              </div>

                              <img
                                v-if="contract.talent.country"
                                :src="`https://flagcdn.com/w80/${contract.talent.country?.code.toLowerCase()}.png`"
                                :alt="contract.talent.country.name"
                                width="24"
                                height="24"
                                class="rounded-circle ms-2"
                              />
                            </div>

                            <div class="d-flex flex-wrap mb-4">
                              <span
                                class="text-dark-50 font-weight-bold mb-lg-0 mb-2"
                                ><i
                                  class="flaticon2-new-email mr-2 font-size-lg"
                                ></i>
                                {{ contract.talent.email || "-" }}</span
                              >
                            </div>
                          </div>

                          <div class="d-flex align-items-start">
                            <div class="d-flex flex-column mr-10">
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Client Name:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold cursor-pointer"
                                    @click="
                                      openLinkInNewTab(
                                        contract.client_project_id
                                          ? `/dashboard/client/${contract.client_id}/project/${contract.client_project_id}`
                                          : '/dashboard/client/' +
                                              contract.client_id
                                      )
                                    "
                                  >
                                    {{
                                      contract.client_name
                                        ? contract.client_name
                                        : "-"
                                    }}
                                  </span></span
                                >
                              </div>

                              <div class="d-flex flex-wrap mb-4">
                                <span
                                  class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2"
                                  ><i
                                    class="flaticon2-new-email mr-2 font-size-lg"
                                  ></i>
                                  {{ contract.client_email || "-" }}</span
                                >
                              </div>
                            </div>
                            <div class="d-flex flex-grow-1 flex-column mr-10">
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Official Squad Name:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold cursor-pointer"
                                  >
                                    {{
                                      contract.project_name
                                        ? contract.project_name
                                        : "-"
                                    }}
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="d-flex flex-grow-1 flex-column mr-10">
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Internal squad name:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold cursor-pointer"
                                  >
                                    {{
                                      contract.project_internal_name
                                        ? contract.project_internal_name
                                        : "-"
                                    }}
                                  </span></span
                                >
                              </div>
                            </div>
                            <div class="d-flex flex-grow-1 flex-column mr-10">
                              <span
                                class="font-size-md grey--text text--darken-1 mr-5 mb-1"
                                ><strong>Start Date:</strong>
                                <span
                                  color="info"
                                  outlined=""
                                  text-color="info"
                                  class="info--text font-weight-bold cursor-pointer"
                                >
                                  {{
                                    contract?.start_date
                                      ? contract.start_date
                                      : "-"
                                  }}
                                </span>
                              </span>
                            </div>
                            <div class="d-flex flex-grow-1 flex-column mr-10">
                              <span
                                class="font-size-md grey--text text--darken-1"
                                ><strong>End Date:</strong>
                                <span
                                  color="info"
                                  outlined=""
                                  text-color="info"
                                  class="info--text font-weight-bold cursor-pointer"
                                >
                                  {{
                                    contract?.end_date ? contract.end_date : "-"
                                  }}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <!--end::Title-->

                        <!--begin::Content-->
                        <div
                          class="d-flex flex-wrap justify-content-between mt-1"
                        >
                          <div class="d-flex flex-column flex-grow-1">
                            <div
                              class="d-flex flex-column flex-md-column align-items-lg-start justify-content-between mb-6"
                            >
                              <div class="d-flex flex-row">
                                <span
                                  class="text-dark-50 font-weight-bold mr-5 mb mb-2 mr-xl-5 d-block"
                                  ><i
                                    class="flaticon2-calendar-3 mr-2 font-size-lg"
                                  ></i>
                                  {{ contract.talent.role?.name || "-" }}</span
                                >
                              </div>
                              <div class="d-flex flex-row">
                                <!-- if role is full stack -->
                                <template
                                  v-if="
                                    contract.talent.role &&
                                    contract.talent.role.id == 3
                                  "
                                >
                                  <span
                                    v-if="contract.talent.BE_lang"
                                    class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i
                                    >{{ contract.talent.BE_lang }} -
                                    {{ contract.talent.experience_years }}

                                    {{
                                      contract.talent.experience_years == 1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                  <span
                                    v-if="contract.talent.FE_lang"
                                    class="text-dark-50 font-weight-bold mb-2 mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i
                                    >{{ contract.talent.FE_lang }} -
                                    {{
                                      contract.talent.second_experience_years
                                    }}

                                    {{
                                      contract.talent.second_experience_years ==
                                      1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                </template>
                                <!-- if role is frontend or backend -->
                                <template
                                  v-else-if="
                                    contract.talent.BE_lang ||
                                    contract.talent.FE_lang
                                  "
                                >
                                  <span
                                    v-if="
                                      contract.talent.BE_lang ||
                                      contract.talent.FE_lang
                                    "
                                    class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-md-auto mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i
                                    >{{
                                      contract.talent.BE_lang ||
                                      contract.talent.FE_lang
                                    }}
                                    -
                                    {{ contract.talent.experience_years }}

                                    {{
                                      contract.talent.experience_years == 1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                </template>
                                <!-- for other roles-->
                                <template
                                  v-else-if="contract.talent.experience_years"
                                >
                                  <span
                                    class="text-dark-50 font-weight-bold mr-5 mb-lg-0 mb-2 mr-md-auto mr-xl-5"
                                    ><i
                                      class="flaticon-statistics mr-2 font-size-lg"
                                    ></i>
                                    {{ contract.talent.experience_years }}
                                    {{
                                      contract.talent.experience_years == 1
                                        ? "year of experience"
                                        : "years of experience"
                                    }}
                                  </span>
                                </template>
                              </div>
                            </div>

                            <div class="d-flex align-items-start mb-6">
                              <div class="d-flex flex-column mr-10">
                                <div>
                                  <span
                                    class="font-size-md grey--text text--darken-1 w-50"
                                    ><strong>Account Manager:</strong
                                    ><span
                                      color="info"
                                      outlined=""
                                      text-color="info"
                                      class="info--text font-weight-bold"
                                    >
                                      {{
                                        contract.account_manager
                                          ? contract.account_manager.name
                                          : "-"
                                      }}
                                    </span></span
                                  >
                                </div>

                                <span
                                  v-if="contract.account_manager?.email"
                                  class="text-muted font-weight-bold text-break clinet-to-talent__email-text"
                                >
                                  {{ contract.account_manager?.email }}
                                </span>
                              </div>
                            </div>
                            <div
                              class="d-flex align-items-start justify-content-between mb-6"
                            >
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Job Type:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold"
                                  >
                                    {{ getJobTitle(contract.job_type) }}
                                  </span></span
                                >
                              </div>
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Contract Type:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold"
                                  >
                                    {{
                                      contract.contract_type == 1
                                        ? "Part time"
                                        : contract.contract_type == 2
                                        ? "Full time"
                                        : "Monthly Hours"
                                    }}
                                  </span></span
                                >
                              </div>
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Renewal type:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold"
                                  >
                                    {{
                                      contract.renewal_type
                                        ? contract.renewal_type
                                        : "-"
                                    }}
                                  </span></span
                                >
                              </div>
                              <div>
                                <span
                                  class="font-size-md grey--text text--darken-1 w-50"
                                  ><strong>Monthly Salary:</strong
                                  ><span
                                    color="info"
                                    outlined=""
                                    text-color="info"
                                    class="info--text font-weight-bold"
                                  >
                                    {{ contract.salary.total_salary }}$
                                  </span></span
                                >
                              </div>
                            </div>

                            <div
                              v-if="
                                contract.rejection &&
                                (contract.renewal_state == 'client_rejected' ||
                                  contract.renewal_state == 'talent_rejected')
                              "
                              class="d-flex align-items-start flex-column justify-content-between pb-4"
                            >
                              <h6 class="v-heading text-h6 text-sm-h6 mb-2">
                                Rejection Reason:
                                <span class="info--text font-weight-bold">
                                  {{ contract.rejection.reason.name }}
                                </span>
                              </h6>
                              <span
                                v-if="
                                  contract.rejection?.confirmed_by &&
                                  contract.rejection.confirmed_by.name
                                "
                                class="text-muted font-weight-bold text-break clinet-to-talent__email-text"
                              >
                                By:
                                {{ contract.rejection.confirmed_by.name }}
                              </span>

                              <div
                                v-if="contract.rejection.comment"
                                class="v-app-ad d-inline-flex flex-child-1 grow-shrink-0 mb-4 v-vuetify-ad v-sheet v-sheet--outlined theme--light rounded p-4"
                                medium="docs"
                                style="width: 100%; min-height: 74px"
                              >
                                {{ contract.rejection.comment }}
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--end::Content-->
                      </div>
                      <!--end::Info-->
                    </div>
                  </div>
                </div>

                <v-card
                  v-if="
                    contract.renewal_state &&
                    contract.renewal_terms &&
                    contract.status == 'active'
                  "
                >
                  <v-tabs
                    background-color="grey lighten-2"
                    centered
                    v-if="!isLoading"
                    v-model="tab"
                  >
                    <v-tabs-slider></v-tabs-slider>

                    <v-tab
                      href="#tab-1"
                      :disabled="contract.renewal_state !== 'client_pending'"
                      class="font-weight-bold text--darken-4"
                    >
                      Pending Client Action
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-tab>

                    <v-tab
                      href="#tab-2"
                      :disabled="contract.renewal_state !== 'operation_pending'"
                      class="font-weight-bold"
                    >
                      Pending Operations
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-tab>
                    <v-tab
                      href="#tab-3"
                      :disabled="contract.renewal_state !== 'talent_rejected'"
                      class="font-weight-bold"
                    >
                      Rejected by Talent
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-tab>
                    <v-tab
                      href="#tab-6"
                      :disabled="contract.renewal_state !== 'client_rejected'"
                      class="font-weight-bold"
                    >
                      Rejected by Client
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-tab>

                    <v-tab
                      href="#tab-4"
                      :disabled="contract.renewal_state !== 'talent_pending'"
                      class="font-weight-bold"
                    >
                      Pending Talent Action
                      <v-icon small>mdi-chevron-right</v-icon>
                    </v-tab>
                    <v-tab
                      href="#tab-5"
                      :disabled="contract.renewal_state !== 'renewed'"
                      class="font-weight-bold"
                    >
                      Renewed
                    </v-tab>
                  </v-tabs>

                  <v-card flat>
                    <v-chip class="ma-2 my-4" color="primary">
                      <strong>Contract New Terms</strong>
                    </v-chip>

                    <div class="table-responsive mb-0">
                      <table
                        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                      >
                        <thead>
                          <tr class="text-center">
                            <th>Start Date</th>
                            <th>End Date</th>

                            <th>Job Type</th>

                            <th>Contract Type</th>

                            <th>Monthly Salary</th>

                            <th>Margin</th>

                            <th>WHT</th>

                            <th>Raise Amount</th>

                            <th>Total Salary</th>

                            <th
                              v-if="
                                !contract.rejection?.is_confirmed &&
                                contract.renewal_state !== 'renewed'
                              "
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ contract.renewal_terms.start_date }}
                            </td>
                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ contract?.renewal_terms.end_date }}
                            </td>

                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ getJobTitle(contract.renewal_terms.job_type) }}
                            </td>

                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{
                                contract.renewal_terms.contract_type == 1
                                  ? "Part time"
                                  : contract.renewal_terms.contract_type == 2
                                  ? "Full time"
                                  : "Monthly Hours"
                              }}
                            </td>

                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ contract.renewal_terms.salary.basic_salary }}
                            </td>

                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ contract.renewal_terms.salary.margin }}
                            </td>

                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ contract.renewal_terms.salary.wht_value }}
                            </td>

                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ contract.renewal_terms.raise }}
                            </td>

                            <td
                              class="text-center text-dark-75 font-weight-bolder font-size-lg"
                            >
                              {{ contract.renewal_terms.salary.total_salary }}
                            </td>

                            <td
                              class="d-flex flex-row"
                              v-if="
                                !contract.rejection?.is_confirmed &&
                                contract.renewal_state !== 'renewed'
                              "
                            >
                              <div
                                class="d-flex"
                                v-if="contract.renewal_state !== 'renewed'"
                              >
                                <v-btn
                                  depressed
                                  color="#f5b400"
                                  class="mr-4"
                                  size="sm"
                                  @click="handleApplyRaise"
                                  v-if="
                                    !contract.renewal_terms.raise_applied &&
                                    contract.renewal_terms.raise > 0
                                  "
                                >
                                  Apply Raise
                                </v-btn>
                                <v-btn
                                  depressed
                                  color="red darken-2"
                                  size="sm"
                                  class="text-white"
                                  @click="handleRevert"
                                  v-if="contract.renewal_terms.raise_applied"
                                >
                                  Reset Raise
                                </v-btn>
                              </div>
                              <b-button
                                v-if="
                                  !contract.rejection?.is_confirmed &&
                                  contract.renewal_state !== 'renewed'
                                "
                                size="sm"
                                class="ml-5"
                                variant="outline-info"
                                :disabled="contract.renewal_terms.raise_applied"
                                @click="showEditContractModal"
                                ><i class="fas fa-pen px-0"></i
                              ></b-button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="ma-4 my-4">
                      <span class="font-size-md grey--text text--darken-1 w-50"
                        ><strong>Notes:</strong
                        ><span
                          color="info"
                          outlined=""
                          text-color="info"
                          class="info--text font-weight-bold"
                        >
                          {{
                            contract.renewal_terms.notes
                              ? contract.renewal_terms.notes
                              : "-"
                          }}
                        </span></span
                      >
                    </div>
                  </v-card>

                  <v-tabs-items v-model="tab" v-if="!isLoading">
                    <v-tab-item :value="'tab-1'">
                      <div class="m-4 d-flex justify-content-end">
                        <v-btn
                          depressed
                          color="#f5b400"
                          class="mr-4"
                          @click="showRejectRenewalModal(2)"
                        >
                          Reject Renewal
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="displayConfirmation('client')"
                        >
                          Renew
                        </v-btn>
                      </div>
                    </v-tab-item>
                    <v-tab-item :value="'tab-2'">
                      <div class="m-4 d-flex justify-content-end">
                        <v-btn
                          depressed
                          color="#f5b400"
                          class="mr-4"
                          @click="notifyTalentWithNewRenewalTerms()"
                        >
                          Notify Talent with New Renewal Terms
                        </v-btn>
                      </div>
                    </v-tab-item>
                    <v-tab-item :value="'tab-3'">
                      <div
                        class="m-4 d-flex justify-content-end"
                        v-if="!contract.rejection?.is_confirmed"
                      >
                        <v-btn
                          depressed
                          color="#f5b400"
                          class="mr-4"
                          @click="notifyClientWithRejection()"
                        >
                          Reject
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="renwew('renewed')"
                        >
                          Accept Renwewal
                        </v-btn>
                      </div>
                    </v-tab-item>
                    <v-tab-item :value="'tab-4'">
                      <!--  show the popup of the rejection and send a resone  -->
                      <div
                        class="m-4 d-flex justify-content-end"
                        v-if="!contract.rejection?.is_confirmed"
                      >
                        <v-btn
                          depressed
                          color="#f5b400"
                          class="mr-4"
                          @click="showRejectRenewalModal(1)"
                        >
                          Reject
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="displayConfirmation('talent')"
                        >
                          Accept Renewal
                        </v-btn>
                      </div>
                    </v-tab-item>
                    <v-tab-item :value="'tab-6'">
                      <div
                        class="m-4 d-flex justify-content-end"
                        v-if="!contract.rejection?.is_confirmed"
                      >
                        <v-btn
                          depressed
                          color="#f5b400"
                          class="mr-4"
                          @click="notifyTalentWithRejection()"
                        >
                          Notify Talent with Rejection
                        </v-btn>
                        <v-btn
                          depressed
                          color="primary"
                          @click="renwew('talent_pending')"
                        >
                          Renew
                        </v-btn>
                      </div>
                    </v-tab-item>
                    <v-tab-item :value="'tab-5'"></v-tab-item>
                  </v-tabs-items>
                </v-card>
                <div v-else>
                  <h5 class="text-center p-2">No Renewal State yet</h5>
                  <div
                    class="m-4 d-flex justify-content-end"
                    v-if="
                      contract.status == 'active' &&
                      contract.renewal_type == 'auto'
                    "
                  >
                    <v-btn
                      depressed
                      color="#f5b400"
                      class="mr-4"
                      @click="earlyRenew"
                    >
                      Early Renew/Terminate
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <EditContractModal
            :contract="activeContract"
            @contractUpdated="fetchContractData()"
          >
            <template #default="{ on }">
              <span ref="contract-modal" v-on="on"></span>
            </template>
          </EditContractModal>

          <RejectRenewalModal
            @renewalRejected="fetchContractData()"
            :type="reasonsListType"
          >
            <template #default="{ on }">
              <span ref="reject-renewal-modal" v-on="on"></span>
            </template>
          </RejectRenewalModal>
        </div>

        <!--end::Row-->
      </div>

      <div class="history-fixed-button">
        <v-btn color="primary" large class="mx-4" @click.stop="showHistory">
          History
        </v-btn>
      </div>

      <v-navigation-drawer
        v-model="contractHistory"
        right
        fixed
        temporary
        width="auto"
        class="contracts-history"
      >
        <v-list-item class="d-flex justify-content-between">
          <h3 class="card-label font-weight-bolder text-dark d-block mr-auto">
            Contract History
          </h3>
          <i
            @click.stop="contractHistory = false"
            class="flaticon-cancel text-dark font-weight-bold my-4 cursor-pointer"
            style="font-size: 2rem"
          ></i>
        </v-list-item>
        <div class="d-flex mt-5 contract-log m-5">
          <div class="d-flex">
            <img
              style="height: 2rem; width: 2rem"
              class="me-2"
              src="@/assets/images/icons/process.png"
            />
            <strong>Contract Log</strong>
          </div>
          <div class="d-flex mt-5" v-if="contractHistoryloading">
            <div class="contract-log__loading">Loading...</div>
          </div>
          <div class="d-flex mt-5" v-else>
            <v-stepper
              v-if="contractHistoryLogs.length"
              vertical
              outlined
              :step="contractHistoryLogs.length + 1"
              class="w-100"
            >
              <v-stepper-step
                :complete="true"
                v-for="(c, i) in contractHistoryLogs"
                :step="i"
                :key="i"
              >
                <div>
                  <div class="d-flex">
                    <strong class="mr-3">
                      {{
                        c.name == "talent_contract_rejection"
                          ? "Rejection"
                          : c.name == "talent_contract_renewal_terms"
                          ? "New contract terms"
                          : ""
                      }}
                      {{
                        c.event == "terminated"
                          ? "Terminated"
                          : capitalizeFirstLetter(c.event)
                      }}
                      by
                    </strong>

                    <span class="font-size-md grey--text text--darken-1">
                      {{ c.created_at }}
                    </span>
                  </div>
                  <div class="d-flex mt-2" v-if="c.user && c.user.name">
                    <span class="font-size-md grey--text text--darken-1">
                      {{
                        c.user?.type == "system"
                          ? "Product"
                          : capitalizeFirstLetter(c.user.type)
                      }}
                      {{ c.user?.type == "system" ? "" : ": " + c.user.name }}
                    </span>
                  </div>
                  <div class="d-flex flex-column mt-2">
                    <div :key="val" v-for="(val, key) in c.new_values">
                      <div
                        class="font-size-md grey--text text--darken-1 mb-3"
                        style="width: 400px; line-height: 22px"
                      >
                        Field
                        <strong>{{ convertUnderscoreToSpace(key) }}</strong
                        >, oldValue{{ key.includes("date") ? " on" : "" }}:
                        <strong>{{
                          c.old_values[key]
                            ? key == "contract_type"
                              ? mapContractType(c.old_values[key])
                              : c.old_values[key]?.name
                              ? c.old_values[key].name
                              : key == "job_type"
                              ? getJobTitle(c.old_values[key])
                              : c.old_values[key]
                            : "-"
                        }}</strong>
                        , New value{{ key.includes("date") ? " on" : "" }}:
                        <span
                          class="info--text font-weight-bold"
                          color="info"
                          outlined
                          text-color="info"
                          >{{
                            val
                              ? key == "contract_type"
                                ? mapContractType(val)
                                : val?.name
                                ? val.name
                                : key == "job_type"
                                ? getJobTitle(val)
                                : val
                              : "-"
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </v-stepper-step>
            </v-stepper>
          </div>
        </div>
      </v-navigation-drawer>

      <!--end::Content-->
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import toasted from "@/core/plugins/vue-toasted";
import { mapActions, mapGetters } from "vuex";
import EditContractModal from "@/components/contract-profile/modals/EditContractModal.vue";
import RejectRenewalModal from "@/components/contract-profile/modals/RejectRenewalModal.vue";

export default {
  name: "BasicInfoCard",
  props: {
    profile: {
      type: Object,
    },
    profileId: {
      type: String,
    },
  },
  components: {
    EditContractModal,
    RejectRenewalModal,
  },
  created() {
    this.fetchContractData();
  },
  data() {
    return {
      default_photo: process.env.BASE_URL + "media/users/blank.png",
      awsBaseUrl: process.env.VUE_APP_AWS_BASE_URL,
      talentProfileUrl: process.env.VUE_APP_TALENT_PROFILE_URL,
      activeClient: null,
      tab: "tab-1",
      activeContract: null,
      isLoading: false,
      reasonsListType: 2,
      contractHistory: false,
      contractHistoryLogs: [],
      contractHistoryloading: true,
    };
  },
  computed: {
    ...mapGetters({
      contract: "contracts/getContractData",
    }),
    photo() {
      let imagePath = this.contract?.talent.image;
      return imagePath || this.default_photo;
    },
  },
  watch: {
    contract: {
      immediate: false,
      deep: true,
      handler() {
        if (!this.contract.renewal_state) {
          this.tab = "";
        }
        if (this.contract.renewal_state == "client_pending") {
          this.tab = "tab-1";
        }
        if (this.contract.renewal_state == "operation_pending") {
          this.tab = "tab-2";
        }
        if (this.contract.renewal_state == "talent_rejected") {
          this.tab = "tab-3";
        }
        if (this.contract.renewal_state == "client_rejected") {
          this.tab = "tab-6";
        }
        if (this.contract.renewal_state == "talent_pending") {
          this.tab = "tab-4";
        }
        if (this.contract.renewal_state == "renewed") {
          this.tab = "tab-5";
        } else {
          this.tab = "";
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getContractData: "contracts/getContractData",
      confirmRenewalState: "contracts/confirmRenewalState",
      renewContract: "contracts/renewContract",
      earlyRenewContract: "contracts/earlyRenewContract",
      handleRaiseRequest: "contracts/handleRaiseRequest",
      getContractHistory: "talentProfile/getContractHistory",
    }),
    lastElement(i, arr) {
      if (i === arr.length - 1) {
        return false;
      } else {
        return true;
      }
    },
    earlyRenew() {
      Swal.fire({
        title: "Are you sure",
        text: "Are you sure you want to Start the renewal cycle?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.earlyRenewContract({
            contract_id: this.contract.id,
          }).then(() => {
            toasted.show("Renewal cycle started Successfully", {
              type: "success",
              icon: "check_circle",
            });
            this.fetchContractData();
          });
        }
      });
    },
    notifyTalentWithNewRenewalTerms() {
      if (
        !this.contract.renewal_terms.raise_applied &&
        this.contract.renewal_terms.raise > 0
      ) {
        Swal.fire({
          title: "Are you sure",
          text: "You want to renew to the talent without the raise?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#6f56c5",
          cancelButtonColor: "#E4E6EF",
          customClass: {
            cancelButton: "text-dark",
            icon: "text-danger border-danger",
          },
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.isConfirmed) {
            this.renwew("talent_pending");
          }
        });
      } else {
        this.renwew("talent_pending");
      }
    },
    getContractStatuses(state) {
      const contractStatuses = [
        {
          text: "Future",
          value: "future",
          color: "primary", // Set color based on state
        },
        {
          text: "Active",
          value: "active",
          color: "success", // Set color based on state
        },
        {
          text: "Terminated",
          value: "terminated",
          color: "error", // Set color based on state
        },
        {
          text: "Ended",
          value: "ended",
          color: "secondary", // Set color based on state
        },
      ];

      return contractStatuses.find((s) => s.value == state);
    },
    displayConfirmation(type) {
      Swal.fire({
        title: "Are you sure",
        text:
          type == "client"
            ? `You will renew on behalf of the client?`
            : `You are wanting to accept renewal on behalf of the ${type}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#6f56c5",
        cancelButtonColor: "#E4E6EF",
        customClass: {
          cancelButton: "text-dark",
          icon: "text-danger border-danger",
        },
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          type == "client"
            ? this.renwew("talent_pending")
            : this.renwew("renewed");
        }
      });
    },
    openLinkInNewTab(url) {
      window.open(url, "_blank");
    },
    showHistory() {
      this.contractHistory = !this.contractHistory;
      if (this.contractHistory) {
        this.contractHistoryloading = true;
        this.getContractHistory({
          resource_id: this.contract.id,
          page: 1,
          logs: true,
        })
          .then((result) => {
            this.contractHistoryLogs = result.data;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.contractHistoryloading = false;
          });
      }
    },
    capitalizeFirstLetter(string) {
      if (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      } else {
        return "";
      }
    },
    mapContractType(contract_type) {
      return contract_type == 1
        ? "Part time"
        : contract_type == 2
        ? "Full time"
        : "Monthly Hours";
    },
    handleApplyRaise() {
      this.handleRaiseRequest({
        contract_id: this.$route.params.id,
        action: "apply",
      }).then(() => {
        toasted.show("Raise Applied Successfully", {
          type: "success",
          icon: "check_circle",
        });
        this.fetchContractData();
      });
    },
    handleRevert() {
      this.handleRaiseRequest({
        contract_id: this.$route.params.id,
        action: "revert",
      }).then(() => {
        toasted.show("Raise Reseted Successfully", {
          type: "success",
          icon: "check_circle",
        });
        this.fetchContractData();
      });
    },
    fetchContractData() {
      this.isLoading = true;
      this.getContractData({ contract_id: this.$route.params.id }).finally(
        () => {
          this.isLoading = false;
        }
      );
    },
    renwew(state) {
      this.renewContract({
        contract_id: this.contract.id,
        data: {
          renewal_state: state,
        },
      }).then(() => {
        toasted.show("Renewal Successful", {
          type: "success",
          icon: "check_circle",
        });
        this.fetchContractData();
      });
    },
    notifyClientWithRejection() {
      this.confirmRenewalState({ contract_id: this.contract.id }).then(() => {
        this.fetchContractData();
        toasted.show("Client rejection is confirmed", {
          type: "success",
          icon: "check_circle",
        });
      });
    },
    notifyTalentWithRejection() {
      this.confirmRenewalState({ contract_id: this.contract.id }).then(() => {
        this.fetchContractData();
        toasted.show("Talent rejection is confirmed", {
          type: "success",
          icon: "check_circle",
        });
      });
    },
    showEditContractModal() {
      this.$refs["contract-modal"].click();
      this.activeContract = { ...this.contract };
    },
    showRejectRenewalModal(type) {
      this.reasonsListType = type;
      this.$refs["reject-renewal-modal"].click();
    },
    convertUnderscoreToSpace(inputString) {
      return inputString.replace(/_/g, " ");
    },
    getJobTitle(type) {
      if (!type) return "-";
      const jobs = [
        {
          text: "Remote",
          value: "remote",
        },
        {
          text: "Onsite",
          value: "onsite",
        },
        {
          text: "Onsite - Iqama",
          value: "onsite-accommodation",
        },
        {
          text: "Onsite - Business Visa",
          value: "onsite-business-visa",
        },
        {
          text: "Hybrid",
          value: "hybrid",
        },
      ];

      return jobs.find((j) => j.value == type)?.text;
    },
  },
};
</script>

<style lang="scss" scoped>
.contract-log__loading {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.contract-log {
  flex-direction: column;
}
</style>

<style lang="scss" scoped>
.profile-avatar {
  .btn-icon {
    right: 0;
    top: 0;
    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
  color: rgb(0 0 0 / 98%);
}

.cv-name {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 300px;
  word-break: break-word;
}

.basic-info__title {
  font-size: 1.2rem;
}

.basic-info__view {
  font-size: 1.2rem;
  color: var(--v-anchor-base);
}

.basic-info__reupload {
  font-size: 1.1rem;
  color: var(--v-success-base);
}

.card-stretch {
  height: calc(100% - 25px) !important;
  min-height: calc(100% - 25px) !important;
}
</style>

<style>
.v-navigation-drawer--temporary {
  z-index: 96 !important;
}

.history-fixed-button {
  display: flex;
  position: fixed;
  top: 60%;
  right: 0;
  transform: rotate(90deg) scale(1.1);
  transform-origin: right top;
}

.contracts-history {
  min-width: 500px;
}

.v-navigation-drawer .card.card-custom > .card-body {
  padding: 1rem !important;
}

.contracts-history-tabs .nav.nav-pills.nav-fill {
  padding: 0;
}

.contracts-history-tabs .card {
  border: none;
}

.contracts-history-tabs .nav.nav-pills .nav-link {
  color: #181c32;
  background-color: #d1d3e0;
  font-weight: 600;
}

.contracts-history-tabs .primary {
  background-color: #fff;
}

.nomination-list {
  height: 80vh;
  overflow-y: auto;
}

.nomination-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.nomination-list::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.nomination-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: darkgrey;
}
</style>
